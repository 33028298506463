import axios from 'axios'
import { API_URL } from '../environment'

// console.log("API_URL", API_URL)
// rest client setup...
const clientConfig = {
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 60000, // 60 seconds
  withCredentials: true
}


const instance = axios.create(clientConfig)

export default instance