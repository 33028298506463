import { createGlobalStyle } from 'styled-components'

/**
 * 100 = hairline
 * 200 = thin
 * 300 = light
 * 400 = regular / normal
 * 500 = medium
 * 600 = semibold
 * 700 = bold
 */
/* eslint-disable no-unused-vars */
import bold_woff from '../fonts/matts/Karbon-Bold.woff'
import bold_otf from '../fonts/matts/Karbon-Bold.otf'
import bold_ttf from '../fonts/matts/Karbon-Bold.ttf'
import bolditalic_woff from '../fonts/matts/Karbon-BoldItalic.woff'
import bolditalic_otf from '../fonts/matts/Karbon-BoldItalic.otf'
import hairline_woff from '../fonts/matts/Karbon-Hairline.woff'
import hairline_otf from '../fonts/matts/Karbon-Hairline.otf'
import hairlineitalic_woff from '../fonts/matts/Karbon-HairlineItalic.woff'
import hairlineitalic_otf from '../fonts/matts/Karbon-HairlineItalic.otf'
import light_woff from '../fonts/matts/Karbon-Light.woff'
import light_otf from '../fonts/matts/Karbon-Light.otf'
import lightitalic_woff from '../fonts/matts/Karbon-LightItalic.woff'
import lightitalic_otf from '../fonts/matts/Karbon-LightItalic.otf'
import medium_woff from '../fonts/matts/Karbon-Medium.woff'
import medium_otf from '../fonts/matts/Karbon-Medium.otf'
import mediumitalic_woff from '../fonts/matts/Karbon-MediumItalic.woff'
import mediumitalic_otf from '../fonts/matts/Karbon-MediumItalic.otf'
import regular_woff from '../fonts/matts/Karbon-Regular.woff'
import regular_otf from '../fonts/matts/Karbon-Regular.otf'
import regular_ttf from '../fonts/matts/Karbon-Regular.ttf'
import regularitalic_woff from '../fonts/matts/Karbon-RegularItalic.woff'
import regularitalic_otf from '../fonts/matts/Karbon-RegularItalic.otf'
import semibold_woff from '../fonts/matts/Karbon-Semibold.woff'
import semibold_otf from '../fonts/matts/Karbon-Semibold.otf'
import semibolditalic_woff from '../fonts/matts/Karbon-SemiboldItalic.woff'
import semibolditalic_otf from '../fonts/matts/Karbon-SemiboldItalic.otf'
import thin_woff from '../fonts/matts/Karbon-Thin.woff'
import thin_otf from '../fonts/matts/Karbon-Thin.otf'
import thinitalic_woff from '../fonts/matts/Karbon-ThinItalic.woff'
import thinitalic_otf from '../fonts/matts/Karbon-ThinItalic.otf'
/* eslint-enable no-unused-vars */


export default createGlobalStyle`

// normal
@font-face { font-family: "Karbon"; font-weight: 100; font-style: normal; src: url(${hairline_woff}) format("woff"); }
@font-face { font-family: "Karbon"; font-weight: 200; font-style: normal; src: url(${thin_woff}) format("woff"); }
@font-face { font-family: "Karbon"; font-weight: 300; font-style: normal; src: url(${light_woff}) format("woff"); }
@font-face { font-family: "Karbon"; font-weight: 400; font-style: normal; src: url(${regular_woff}) format("woff"); }
@font-face { font-family: "Karbon"; font-weight: 500; font-style: normal; src: url(${medium_woff}) format("woff"); }
@font-face { font-family: "Karbon"; font-weight: 600; font-style: normal; src: url(${semibold_woff}) format("woff"); }
@font-face { font-family: "Karbon"; font-weight: 700; font-style: normal; src: url(${bold_woff}) format("woff"); }

// italic
@font-face { font-family: "Karbon"; font-weight: 100; font-style: italic; src: url(${hairlineitalic_woff}) format("woff"); }
@font-face { font-family: "Karbon"; font-weight: 200; font-style: italic; src: url(${thinitalic_woff}) format("woff"); }
@font-face { font-family: "Karbon"; font-weight: 300; font-style: italic; src: url(${lightitalic_woff}) format("woff"); }
@font-face { font-family: "Karbon"; font-weight: 400; font-style: italic; src: url(${regularitalic_woff}) format("woff"); }
@font-face { font-family: "Karbon"; font-weight: 500; font-style: italic; src: url(${mediumitalic_woff}) format("woff"); }
@font-face { font-family: "Karbon"; font-weight: 600; font-style: italic; src: url(${semibolditalic_woff}) format("woff"); }
@font-face { font-family: "Karbon"; font-weight: 700; font-style: italic; src: url(${bolditalic_woff}) format("woff"); }

html {
  font-family: Karbon, Tahoma, Geneva, Verdana, sans-serif;
  scroll-behavior: smooth;
}
`