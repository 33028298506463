import client from './axios'
import React from 'react'
import { useQuery, useQueryClient } from 'react-query'
import GlobalStoreContext from '../components/GlobalContext'



const useGetProMember = () => {
  const { isInSession } = React.useContext(GlobalStoreContext)
  return useQuery(
    'PRO_MEMBER',
    async () => {
      const { data } = await getProStatus()
      return data
    },
    {
      retry: 1,
      enabled: isInSession === true,
      onError: () => {
        logoutSession()
      }
    }
  )
}

const getFeatureStatus = () => {
  return client.get('/feature-status')
}

const status = () => {
  return client.get('/status')
}

const getProStatus = () => {
  return client.get('/pro/status')
}

const sendOTP = (mobileNum) => {
  return client.post('/sendOTP', { mobileNum })
}

const verifyOTP = (totp) => {
  return client.post('/verifyOTP', { totp })
}

const resendOTP = () => {
  return client.get('/resendOTP')
}

const getDataHolders = () => {
  return client.get('/data-holders')
}

const getConsentLanguage = () => {
  return client.get('/lang/consent')
}

const createUser = () => {
  return client.post('/user')
}

const createUserConsent = (payload) => {
  return client.post('/user/consent', payload)
}

const updateUserConsent = (payload) => {
  return client.patch('/user/consent', payload)
}

const fetchCDRData = (data) => {

  return client.post('/user/consent/progress', data)
}

const getClusterProgress = () => {
  return client.get('/user/consent/progress')
}

const getProgressSummary = () => {
  return client.get('/user/consent/progress/summary')
}

const logoutSession = () => {
  return client.get('/logout')
}

const getPublicRedirectBaseUrl = () => {
  return client.get('/redirectUrl')
}

const getCDRDashboardUrl = () => {
  return client.get('/consumberdashboarUrl')
}

const downloadPDF = () => {
  return client.get('/pdf')
}

const getNext = () => {
  return client.get('/next')
}

/// commercial api

const sendCommOTP = (accessKey, mobileNum) => {
  return client.post('/comm/sendOTP', { accessKey, mobileNum })
}

const storeClusters = () => {
  return client.post('/user/consent/progress/complete')
}

const validateAuthCallBackState = (state) => {
  return client.get(`/user/consent/callback/${state}`)
}

const getUserDetail = () => {
  return client.get(`/comm/user`)
}

const getUserConsentsList = () => {
  return client.get(`/comm/consents`)
}

const collectData = (consentId) => {
  return client.post(`/comm/consents/collect/${consentId}`)
}

// similar to the one above but use the consentId from session
const recollectData = () => {
  return client.post(`/comm/consent/recollect/collect`)
}

const revokeConsent = (consentId) => {
  return client.patch(`/comm/consents/${consentId}/revoke`)
}

const dataHolderStatus = (dataHolderId) => {
  return client.get(`/data-holders/${dataHolderId}/status`)
}


const API = {
  status,
  sendOTP,
  verifyOTP,
  resendOTP,
  getDataHolders,
  getConsentLanguage,
  createUser,
  createUserConsent,
  updateUserConsent,
  fetchCDRData,
  getClusterProgress,
  getProgressSummary,
  logoutSession,
  getPublicRedirectBaseUrl,
  getCDRDashboardUrl,
  downloadPDF,
  getNext,
  sendCommOTP,
  storeClusters,
  validateAuthCallBackState,
  getUserConsentsList,
  collectData,
  revokeConsent,
  useGetProMember,
  recollectData,
  getUserDetail,
  dataHolderStatus,
  getFeatureStatus
}

export default API