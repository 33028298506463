// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-collectingyourdata-9-js": () => import("./../../../src/pages/collectingyourdata-9.js" /* webpackChunkName: "component---src-pages-collectingyourdata-9-js" */),
  "component---src-pages-congrats-10-js": () => import("./../../../src/pages/congrats-10.js" /* webpackChunkName: "component---src-pages-congrats-10-js" */),
  "component---src-pages-connectwithyourbank-8-js": () => import("./../../../src/pages/connectwithyourbank-8.js" /* webpackChunkName: "component---src-pages-connectwithyourbank-8-js" */),
  "component---src-pages-howitworks-2-js": () => import("./../../../src/pages/howitworks-2.js" /* webpackChunkName: "component---src-pages-howitworks-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-introduction-1-js": () => import("./../../../src/pages/introduction-1.js" /* webpackChunkName: "component---src-pages-introduction-1-js" */),
  "component---src-pages-letsgetyouverified-3-js": () => import("./../../../src/pages/letsgetyouverified-3.js" /* webpackChunkName: "component---src-pages-letsgetyouverified-3-js" */),
  "component---src-pages-letsgetyouverifiedotp-4-js": () => import("./../../../src/pages/letsgetyouverifiedotp-4.js" /* webpackChunkName: "component---src-pages-letsgetyouverifiedotp-4-js" */),
  "component---src-pages-pro-configureyourconsent-2-js": () => import("./../../../src/pages/pro/configureyourconsent-2.js" /* webpackChunkName: "component---src-pages-pro-configureyourconsent-2-js" */),
  "component---src-pages-pro-consentdetails-js": () => import("./../../../src/pages/pro/consentdetails.js" /* webpackChunkName: "component---src-pages-pro-consentdetails-js" */),
  "component---src-pages-pro-dashboard-js": () => import("./../../../src/pages/pro/dashboard.js" /* webpackChunkName: "component---src-pages-pro-dashboard-js" */),
  "component---src-pages-pro-js": () => import("./../../../src/pages/pro.js" /* webpackChunkName: "component---src-pages-pro-js" */),
  "component---src-pages-selectyourbank-5-js": () => import("./../../../src/pages/selectyourbank-5.js" /* webpackChunkName: "component---src-pages-selectyourbank-5-js" */),
  "component---src-pages-selectyourdata-6-js": () => import("./../../../src/pages/selectyourdata-6.js" /* webpackChunkName: "component---src-pages-selectyourdata-6-js" */),
  "component---src-pages-summary-7-js": () => import("./../../../src/pages/summary-7.js" /* webpackChunkName: "component---src-pages-summary-7-js" */),
  "component---src-pages-viewdata-11-js": () => import("./../../../src/pages/viewdata-11.js" /* webpackChunkName: "component---src-pages-viewdata-11-js" */)
}

