import { navigate } from 'gatsby-link'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import api from '../utils/api'

const defaultState = {
  isInSession: null,
  filterIsActive: 'active',
  filterSearchString: null,
  filterSelectDH: [],
  filterSelectScopes: [],
  amendConsent: null,
  featureStatus: [],
  setInSession: () => { },
  setFilterIsActive: () => { },
  setFilterSearchString: () => { },
  setFilterSelectDH: () => { },
  setFilterSelectScopes: () => { },
  setAmendConsent: () => { },
  setFeatureStatus: () => { },
}

export const GlobalContext = React.createContext(defaultState)

const publicPath = ['/collectingyourdata-v2', '/collectingyourdata-9', '/letsgetyouverifiedotp-4', '/letsgetyouverified-3', '/howitworks-2', '/introduction-1', '/pro', '/os-credits']

const redirectIfNoSession = (path, isInSession) => {
  let newPath = path
  if (newPath.endsWith('/')) {
    newPath = newPath.slice(0, -1);
  }
  // console.log("redirect if no session", path, isInSession, publicPath.includes(newPath))
  if (isInSession !== null) {
    if (!publicPath.includes(newPath) && !(isInSession && isInSession)) {
      console.debug('GlobalContext: User is NOT in session -> redirecting to mainpage')
      navigate('/introduction-1')
    }
  }
}



export const GlobalProvider = ({ children = null, path = '/', ...props }) => {
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState(() => {
    return {
      isInSession: null,
      filterIsActive: 'active',
      filterSearchString: null,
      filterSelectDH: [],
      filterSelectScopes: [],
      amendConsent: null,
      featureStatus: [],
      setInSession: (newState) => setState((ps) => ({ ...ps, isInSession: newState })),
      setFilterIsActive: (isActiveText) => setState((ps) => ({ ...ps, filterIsActive: isActiveText })),
      setFilterSearchString: (searchString) => setState((ps) => ({ ...ps, filterSearchString: searchString })),
      setFilterSelectDH: (dhArray) => setState((ps) => ({ ...ps, filterSelectDH: [...dhArray] })),
      setFilterSelectScopes: (scopesArray) => setState((ps) => ({ ...ps, filterSelectScopes: [...scopesArray] })),
      setAmendConsent: (existingConsent) => setState((ps) => ({ ...ps, amendConsent: { ...existingConsent } })),
      setFeatureStatus: (newState) => setState((ps) => ({ ...ps, featureStatus: newState })),
    }
  })

  // watch if user has session
  const { isSuccess, isError } = useQuery('session', async () => {
    const data = await api.status()
    return data
  },
    {
      staleTime: 0,
      retry: 0,
      staleTime: 1 * 60 * 1000, // 1 mins
      onSuccess: () => setState((ps) => { return { ...ps, isInSession: true } }),
      onError: () => setState((ps) => { return { ...ps, isInSession: false } })
    })

  useQuery('featureStatus', async () => {
    return await api.getFeatureStatus();
  }, {
    retry: 0,
    onSuccess: (response) => setState((ps) => { 
      return { ...ps, featureStatus: response.data };
    }),
    onError: () => setState((ps) => { return { ...ps, featureStatus: [] } })
  });

  // check on initial page load
  React.useEffect(() => redirectIfNoSession(path, state.isInSession), [state.isInSession, path, isError])


  return <GlobalContext.Provider value={state}>{children}</GlobalContext.Provider>
}
GlobalProvider.propTypes = {
  children: PropTypes.any
}

export default GlobalContext
