/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import './src/scss/theme.scss'
import GlobalStyle from './src/theme/global-style'

import React from "react"
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { GlobalProvider } from './src/components/GlobalContext'

const queryClient = new QueryClient()

// wrapRootElement doesnt support path
// export const wrapRootElement = ({ element }) => {
//   return <Elements stripe={stripePromise}>{element}</Elements>
// }

export const wrapPageElement = ({ element, props }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyle />
      <GlobalProvider {...props}>{element}</GlobalProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
// wrapRootElement.propTypes = {
//   element: PropTypes.any
// }
